<template>
  <div class="col">
    <!-- Start All Seats  -->
    <card>
      <h5 class="title_requests">
        {{ $t("admin_dashboard_type.exist_requests") }}
      </h5>
      <server-table
        :DataItems="seatsList"
        :count="seatsListCount"
        @Refresh="Refresh"
        :useFilter="false"
        :enable_export="false"
        :action="false"
      >
        <template #columns>
          <el-table-column
            prop="name"
            :label="$t('admin_dashboard_type.school_name')"
          >
          </el-table-column>
          <el-table-column
            prop="seats_count"
            :label="$t('admin_dashboard_type.students_number')"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            prop="seats_number"
            :label="$t('admin_dashboard_type.request_numbers_seats')"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            prop="max_seats"
            :label="$t('admin_dashboard_type.exist_seats_number')"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            prop="status"
            :label="$t('admin_dashboard_type.request_date')"
          >
            <template slot-scope="scope">
              {{
                scope.row.created_at
                  ? toLocalDatetime(scope.row.created_at, "en")
                  : "___"
              }}
            </template>
          </el-table-column>
          <el-table-column prop="status" :label="$t('Global.status')">
            <template slot-scope="scope">
              <div class="d-flex justify-content-center">
                <span
                  class="d-block status_value pending"
                  v-if="scope.row.status == 3 || scope.row.status == 2"
                >
                  {{ $t("admin_dashboard_type.Pending") }}
                </span>
                <span
                  class="d-block status_value approved"
                  v-if="scope.row.status == 1"
                >
                  {{ $t("admin_dashboard_type.Approved") }}
                </span>
                <span
                  class="d-block status_value declined"
                  v-if="scope.row.status == 0"
                >
                  {{ $t("admin_dashboard_type.Declined") }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="_">
            <template slot-scope="scope">
              <!-- <div class="icons_table_wrapper"> -->
              <div class="d-flex justify-content-center">
                <span
                  class="d-block status_value accept"
                  @click="acceptSeatsRequest(scope.row.id)"
                >
                  {{ $t("admin_dashboard_type.accept_request") }}
                </span>
              </div>
            </template>
          </el-table-column>
        </template>
      </server-table>
    </card>
    <!-- End All Seats  -->
    <!-- Start All Seats History  -->
    <card>
      <h5 class="title_requests">
        {{ $t("admin_dashboard_type.requests_list") }}
      </h5>
      <server-table
        :DataItems="adminRequestsHistory"
        :count="adminRequestsHistoryCount"
        @Refresh="getListOfSeatsHistory"
        :useFilter="false"
        :enable_export="false"
        :action="false"
      >
        <template #columns>
          <el-table-column
            prop="name"
            :label="$t('admin_dashboard_type.school_name')"
          >
          </el-table-column>
          <el-table-column
            prop="seats_count"
            :label="$t('admin_dashboard_type.students_number')"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            prop="seats_number"
            :label="$t('admin_dashboard_type.request_numbers_seats')"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="max_seats"
            :label="$t('admin_dashboard_type.exist_seats_number')"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            prop="status"
            :label="$t('admin_dashboard_type.request_date')"
          >
            <template slot-scope="scope">
              {{
                scope.row.created_at
                  ? toLocalDatetime(scope.row.created_at, "en")
                  : "___"
              }}
            </template>
          </el-table-column>
          <el-table-column prop="status" :label="$t('Global.status')">
            <template slot-scope="scope">
              <div class="d-flex justify-content-center">
                <span
                  class="d-block status_value declined"
                  v-if="scope.row.status == 0"
                >
                  {{ $t("admin_dashboard_type.Rejected") }}
                </span>
                <span
                  class="d-block status_value approved"
                  v-if="scope.row.status == 1"
                >
                  {{ $t("admin_dashboard_type.Accepted") }}
                </span>
                <span
                  class="d-block status_value pending"
                  v-if="scope.row.status == 3"
                >
                  {{ $t("admin_dashboard_type.Pending") }}
                </span>
              </div>
            </template>
          </el-table-column>
        </template>
      </server-table>
    </card>
    <!-- Start All Seats History  -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ServerTable from "../../components/DataTablePagination.vue";
import Swal from "sweetalert2";
export default {
  components: { ServerTable },
  mounted() {
    this.Refresh();
    this.getListOfSeatsHistory();
  },
  computed: {
    ...mapGetters({
      loadingValue: ["schools/load"],
      seatsList: ["schools/seatsList"],
      seatsListCount: ["schools/seatsListCount"],
      adminRequestsHistory: ["schools/adminRequestsHistory"],
      adminRequestsHistoryCount: ["schools/adminRequestsHistoryCount"],
    }),
    offset() {
      if (this.$route.query.currentPage && this.$route.query.perPage) {
        return (this.$route.query.currentPage - 1) * this.$route.query.perPage;
      } else {
        return 0;
      }
    },

    limit() {
      if (this.$route.query.perPage) {
        return this.$route.query.perPage - 1;
      } else {
        return 4;
      }
    },
    // for history
    offsetHistory() {
      if (this.$route.query.currentPage && this.$route.query.perPage) {
        return (this.$route.query.currentPage - 1) * this.$route.query.perPage;
      } else {
        return 0;
      }
    },
    limitHistory() {
      if (this.$route.query.perPage) {
        return this.$route.query.perPage - 1;
      } else {
        return 4;
      }
    },
  },
  methods: {
    Refresh(query = { offset: this.offset, limit: this.limit }) {
      this.$store.dispatch("schools/getListOfSeats", { query: query });
    },
    acceptSeatsRequest(id) {
      // let formData = new FormData();
      // formData.append("id", id);
      this.$store
        .dispatch("schools/handlerRegionApproveSeats", { id: id })
        .then(() => {
          Swal.fire({
            text: `${this.$t("success_accepted_successfully")}`,
            icon: "success",
            showCancelButton: false,
            confirmButtonClass: "btn btn-success btn-fill",
            confirmButtonText: `${this.$t("confirm")}`,
            buttonsStyling: false,
          });
          this.Refresh();
        });
    },
    getListOfSeatsHistory(
      query = {
        offset: this.offsetHistory,
        limit: this.limitHistory,
      }
    ) {
      this.$store.dispatch("schools/getListHistoryOfSeats", { query: query });
    },
  },
};
</script>

<style lang="scss" scoped>
.title_requests {
  color: #555;
  text-align: initial;
  margin-bottom: 20px;
  text-transform: capitalize;
}
::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
.icons_table_wrapper {
  display: flex;
  gap: 8px;
  justify-content: end;
  padding: 5px 0;
  .icon {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    cursor: pointer;
    &.edit {
      background-color: #409eff;
      color: #fff;
    }
    &.remove {
      background-color: #fb404b;
      color: #fff;
    }
  }
}
.status_value {
  padding: 5px 17px;
  width: fit-content;
  border-radius: 20px;
  &.pending {
    background-color: #11bfe3;
    color: #ffffff;
  }
  &.approved {
    background-color: #87cb16;
    color: #ffffff;
  }
  &.declined {
    background-color: #f56c6c;
    color: #ffffff;
  }
  &.accept {
    background-color: #67c23a;
    color: #ffffff;
    cursor: pointer;
    border-radius: 5px;
    padding: 7px 17px;
  }
}
</style>
